import React, { FC, useState } from "react";
import { toRad, circlePath, drawTiltedTick } from "./Utils";
import InfoCard from "../InfoCard";

interface MonthType {
  label: string;
  short: string;
  start: number;
  image: string;
  description: string;
}

const MONTHS: MonthType[] = [
  {
    label: "January",
    short: "Jan",
    start: 279.6625,
    image: "/data/calendar/january.jpg",
    description: `## Named After Janus

**January** is the first month of the year in the Gregorian calendar. It is named after **Janus**, the Roman god of beginnings and endings, who is depicted with two faces looking to the past and future. January is often the coldest month in the Northern Hemisphere, associated with new beginnings and resolutions.`,
  },
  {
    label: "February",
    short: "Feb",
    start: 311.2175,
    image: "/data/calendar/february.jpg",
    description: `## The Shortest Month

**February** is named after **Februa**, an ancient Roman festival of purification. It is the second month and has **28 days** in common years and **29 days** in leap years. Astronomically, it is when the Northern Hemisphere starts to slowly emerge from winter's grip.`,
  },
  {
    label: "March",
    short: "Mar",
    start: 340.51055556,
    image: "/data/calendar/march.jpg",
    description: `## Welcoming Spring

**March** is named after **Mars**, the Roman god of war. Historically, it was the first month of the Roman calendar. March marks the beginning of spring with the **March equinox**, bringing milder temperatures and longer days.`,
  },
  {
    label: "April",
    short: "Apr",
    start: 11.33138889,
    image: "/data/calendar/april.jpg",
    description: `## A Time of Bloom

**April** may derive its name from the Latin word **"aperire"**, meaning "to open," referring to the blossoming of flowers and trees. It's a month associated with growth and renewal in the Northern Hemisphere.`,
  },
  {
    label: "May",
    short: "May",
    start: 40.69277778,
    image: "/data/calendar/may.jpg",
    description: `## Named After Maia

**May** is named after **Maia**, the Roman goddess of spring and growth. This month sees further warming temperatures and the full bloom of flora. It's a time of fertility and abundance in nature.`,
  },
  {
    label: "June",
    short: "Jun",
    start: 70.58055556,
    image: "/data/calendar/june.jpg",
    description: `## The Start of Summer

**June** is named after **Juno**, the Roman queen of the gods and goddess of marriage and childbirth. The **June solstice** marks the beginning of summer and the longest day of the year in the Northern Hemisphere.`,
  },
  {
    label: "July",
    short: "Jul",
    start: 99.24027778,
    image: "/data/calendar/july.jpg",
    description: `## Honoring Julius Caesar

**July** is named in honor of **Julius Caesar**, who was born during this month. It's typically one of the warmest months, ideal for outdoor activities and vacations. The night skies often feature meteor showers like the **Delta Aquariids**.`,
  },
  {
    label: "Augest",
    short: "Aug",
    start: 128.82458333,
    image: "/data/calendar/august.jpg",
    description: `## Named for Augustus

**August** is named after **Emperor Augustus Caesar**. This month often brings the peak of summer heat. Astronomically, the **Perseid meteor shower** occurs in August, providing spectacular night sky viewing opportunities.`,
  },
  {
    label: "September",
    short: "Sep",
    start: 158.63075,
    image: "/data/calendar/september.jpg",
    description: `## Transition to Fall

**September** derives from the Latin **"septem"**, meaning "seven," as it was the seventh month in the ancient Roman calendar. It marks the beginning of fall with the **September equinox**, bringing cooler temperatures and shorter days.`,
  },
  {
    label: "October",
    short: "Oct",
    start: 187.88094444,
    image: "/data/calendar/october.jpg",
    description: `## The Eighth Month

**October** comes from the Latin **"octo"**, meaning "eight," as it was the eighth month in the Roman calendar. It's a month known for harvest festivals and the changing colors of leaves. The **Orionid meteor shower** can be observed in October.`,
  },
  {
    label: "November",
    short: "Nov",
    start: 218.66872222,
    image: "/data/calendar/november.jpg",
    description: `## The Ninth Month

**November** is derived from the Latin **"novem"**, meaning "nine." It was the ninth month in the ancient Roman calendar. In the Northern Hemisphere, November brings colder weather and the first signs of winter. The **Leonid meteor shower** typically occurs during this month.`,
  },
  {
    label: "December",
    short: "Dec",
    start: 248.89025,
    image: "/data/calendar/december.jpg",
    description: `## Year's End

**December** comes from the Latin **"decem"**, meaning "ten," as it was the tenth month in the Roman calendar. It marks the beginning of winter with the **December solstice**, the shortest day and the longest night of the year in the Northern Hemisphere. December is associated with various cultural and religious holidays.`,
  },
];

interface Props {
  rStart: number;
  rEnd: number;
  fg: string;
  bg: string;
  canvas: number[];
}

const MonthCircle: FC<Props> = ({ rStart, rEnd, fg, bg, canvas }) => {
  const [selectedMonth, setSelectedMonth] = useState<null | MonthType>(null);

  const handleOpen = (Month: MonthType) => {
    setSelectedMonth(Month);
  };

  const handleClose = () => {
    setSelectedMonth(null);
  };

  return (
    <>
      <circle
        key={`month-circle`}
        fill="none"
        strokeWidth="1"
        stroke={fg}
        cx="50%"
        cy="50%"
        r={rStart}
      />
      <g>
        {MONTHS.map((month) =>
          drawTiltedTick(
            rStart,
            rEnd,
            toRad(month.start),
            fg,
            `month-tick-${month.label}`,
            canvas,
          ),
        )}
      </g>
      <g>
        <defs>
          {circlePath((rStart + rEnd) / 2 - 5, "months-circle", fg, canvas)}
        </defs>
        {MONTHS.map((month, index) => (
          <text
            key={`month-circle-${month}-${index}`}
            fill={fg}
            fontSize="16px"
            onClick={() => handleOpen(month)}
            cursor={"pointer"}
            transform={`rotate(${month.start - 180 + 1} ${canvas[0] / 2} ${canvas[1] / 2})`}
          >
            <textPath xlinkHref="#months-circle">{month.label}</textPath>
          </text>
        ))}
        {selectedMonth && (
          <InfoCard
            open={!!selectedMonth}
            onClose={handleClose}
            image={selectedMonth.image}
            title={selectedMonth.label}
            description={selectedMonth.description}
          />
        )}
      </g>
    </>
  );
};

export default MonthCircle;
