import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  MenuItem,
  Box,
  IconButton,
  Menu,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import { useThemeContext } from "../context/ThemeContext";
import { useAuthContext } from "../context/AuthContext";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";

const Navbar: React.FC = () => {
  const { toggleTheme, mode } = useThemeContext();
  const { user, logout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleCloseMenu();
    navigate("/");
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src="data/logo.svg"
            alt="Logo"
            style={{ width: 40, height: 40, cursor: "pointer" }}
          />
        </Box>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <MenuItem
            component={Link}
            to="/"
            selected={location.pathname === "/"}
          >
            Home
          </MenuItem>
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton color="inherit" onClick={toggleTheme}>
            {mode === "light" ? <NightlightRoundIcon /> : <WbSunnyIcon />}
          </IconButton>

          {user ? (
            <>
              <IconButton color="inherit" onClick={handleProfileClick}>
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/account");
                    handleCloseMenu();
                  }}
                >
                  Account
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <IconButton color="inherit" onClick={() => navigate("/login")}>
              <ExitToApp />
            </IconButton>
          )}

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/"
              selected={location.pathname === "/"}
            >
              Home
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
