import React from "react";
import { PlanetSVGProps } from "./../types";

const UranusLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="M212.1357422,153.3461914h31.9052734V146.40625h-31.9042969c1.1875-44.5415039,25.0029297-83.4130859,60.0302734-97.40625
	l-2.5761719-6.4450684c-37.6259766,15.032959-63.1904297,56.4934082-64.3935547,103.8513184h-51.7275391V45.7775879h-6.9394531
	V146.40625H94.8017578c-1.203125-47.3312988-26.7675781-88.7746582-64.3935547-103.809082l-2.5751953,6.4453125
	c35.0263672,13.9943848,58.8417969,52.848877,60.0302734,97.3637695H55.9580078v6.9399414h31.9052734
	c-1.1738281,44.5490723-24.9931641,83.40625-60.0273438,97.3605957l2.5693359,6.4484863
	c37.6396484-14.9938965,63.2080078-56.4404297,64.3974609-103.809082h51.7275391v38.734375
	c-16.4472656,1.7392578-29.3056641,15.6901855-29.3056641,32.5895996c0,18.0715332,14.703125,32.7749023,32.7753906,32.7749023
	c18.0712891,0,32.7744141-14.7033691,32.7744141-32.7749023c0-16.8994141-12.8583984-30.8503418-29.3046875-32.5895996v-38.734375
	h51.7265625c1.1904297,47.3500977,26.7578125,88.8129883,64.3945312,103.8515625l2.5761719-6.4453125
	C237.1298828,236.7536621,213.3105469,197.878418,212.1357422,153.3461914z M175.8349609,224.670166
	c0,14.2458496-11.5898438,25.8349609-25.8349609,25.8349609c-14.2460938,0-25.8349609-11.5891113-25.8349609-25.8349609
	S135.7539062,198.8352051,150,198.8352051C164.2451172,198.8352051,175.8349609,210.4243164,175.8349609,224.670166z"
        />
      </svg>
    </g>
  );
};

export default UranusLine;
