import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Leo: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M232.1699219,240.6170654c-6.4560547,6.4553223-15.0429688,10.0117188-24.171875,10.0117188
	c-9.1318359,0-17.7158203-3.5546875-24.1669922-10.0134277c-2.4941406-2.4907227-4.578125-5.3371582-6.1982422-8.4631348
	c-3.1621094-6.0979004-4.1513672-13.3952637-2.8632812-21.1060791l20.3964844-121.9771729
	c0.9277344-5.5488281,0.8261719-10.699585-0.2988281-15.3081055c-4.5068359-18.4460449-20.9316406-31.3295898-39.9453125-31.3295898
	c-6.5703125,0-13.1074219,1.5875244-18.9013672,4.5915527c-16.1396484,8.3681641-24.5068359,27.8273926-19.8876953,46.2717285
	l14.9404297,59.7073975c-7.2333984-6.3250732-16.6865234-10.1706543-27.0283203-10.1706543
	c-22.6728516,0-41.1210938,18.4460449-41.1210938,41.1193848c0,22.6749268,18.4482422,41.1209717,41.1210938,41.1209717
	c22.6738281,0,41.1181641-16.3423615,41.1181641-41.1209717c0-12.8683777-22.3007812-92.34021-22.3007812-92.34021
	c-3.8427734-15.3607178,3.0332031-31.5227051,16.3505859-38.427124c4.8828125-2.5296631,10.1660156-3.8121338,15.7089844-3.8121338
	c15.8046875,0,29.4580078,10.7062988,33.2021484,26.038208c0.9042969,3.6884766,0.96875,7.8988037,0.1962891,12.5142822
	l-20.3955078,121.9770508c-1.5390625,9.2053223-0.3125,18.0056152,3.5478516,25.4453125
	c1.9443359,3.7596436,4.4521484,7.1838379,7.4511719,10.1777344c7.7636719,7.7667236,18.0888672,12.0449219,29.0742188,12.0449219
	c10.9824219,0,21.3115234-4.2781982,29.078125-12.0449219L232.1699219,240.6170654z M104.0449219,218.1317139
	c-18.8476562,0-34.1806641-15.3334961-34.1806641-34.1810303c0-18.8458252,15.3330078-34.1794434,34.1806641-34.1794434
	c18.8447266,0,34.1777344,15.3336182,34.1777344,34.1794434
	C138.2226562,202.7982178,122.8896484,218.1317139,104.0449219,218.1317139z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Leo;
