import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Taurus: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M199.3588867,78.1745605c6.9365234-17.2277832,24.6591797-28.8033447,44.0996094-28.8033447v-6.9399414
	c-22.2426758,0-42.5541992,13.3223877-50.5380859,33.151001C185.1708984,94.824585,171.4331055,117.7623291,150,117.7623291
	c-21.4365234,0-35.1704102-22.9377441-42.9169922-42.1800537c-7.9833984-19.8286133-28.2949219-33.151001-50.5415039-33.151001
	v6.9399414c19.4443359,0,37.1665039,11.5755615,44.1030273,28.8033447
	c8.4355469,20.9549561,19.1538086,35.1077881,31.550293,41.8934326
	c-29.9482422,7.8947754-52.0986328,35.2043457-52.0986328,67.5966797c0,38.5456543,31.3583984,69.9040527,69.9038086,69.9040527
	c38.5458984,0,69.9042969-31.3583984,69.9042969-69.9040527c0-32.3928223-22.1513672-59.7026367-52.1000977-67.5969238
	C180.199707,113.2818604,190.9189453,99.1290283,199.3588867,78.1745605z M212.9643555,187.6646729
	c0,34.7182617-28.2441406,62.9641113-62.9643555,62.9641113c-34.7197266,0-62.9638672-28.2458496-62.9638672-62.9641113
	S115.2802734,124.7021484,150,124.7021484C184.7202148,124.7021484,212.9643555,152.9464111,212.9643555,187.6646729z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Taurus;
