import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Scorpio: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.6;
  const width = height;
  const margin = (rEnd - rStart) * 0.2;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 1.5 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={20}
        fill={bg}
        d="M385.1157227,192.3799438l-34.9399414-34.9418945l-34.9438477,34.9418945l4.9072266,4.9067383l26.5664062-26.5653076
	v36.1635742c0,20.9587402-17.0512695,38.0085449-38.0097656,38.0085449c-20.9589844,0-38.0073242-17.0498047-38.0073242-38.0085449
	V93.1150513c0-24.7844238-20.1621094-44.9484863-44.9467773-44.9484863c-18.6533203,0-34.6850586,11.4239502-41.4770508,27.6413574
	c-6.7924805-16.2174072-22.8261719-27.6413574-41.4799805-27.6413574c-18.6533203,0-34.6845703,11.4237061-41.4765625,27.6407471
	C94.5161133,59.590271,78.484375,48.1665649,59.8310547,48.1665649c-24.784668,0-44.9467773,20.1640625-44.9467773,44.9484863
	h6.9399414c0-20.9586182,17.0483398-38.0085449,38.0068359-38.0085449s38.0068359,17.0499268,38.0068359,38.0085449v128.3139648
	h6.9399414V93.1150513c0-20.9586182,17.0483398-38.0085449,38.0068359-38.0085449
	c20.9589844,0,38.0102539,17.0499268,38.0102539,38.0085449v128.3139648h6.9399414V93.1150513
	c0-20.9586182,17.0483398-38.0085449,38.0068359-38.0085449c20.9589844,0,38.0068359,17.0499268,38.0068359,38.0085449v113.7698975
	c0,24.7844238,20.1625977,44.9484863,44.9472656,44.9484863c24.7841797,0,44.9501953-20.1640625,44.9501953-44.9484863v-36.1633301
	l26.5634766,26.5650635L385.1157227,192.3799438z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Scorpio;
