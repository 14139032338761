import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { fetchEclipticPositions } from "./../../services/authService";

import Horizon from "./Horizon";
import { Intersections, Celestials } from "./types";
import ZodiacCircle from "./ZodiacCircle";
import SeasonCircle from "./SeasonCircle";
import MonthCircle from "./MonthCircle";
import DayCircle from "./DayCircle";
import CelestialCircle from "./CelestialCircle";

interface Props {
  rCelestials: number;
  rDays: number;
  rMonths: number;
  rSeasons: number;
  rZodiac: number;
  rEnd: number;
  canvas: number[];
}

export const Astrolabe: React.FC<Props> = ({
  rCelestials,
  rDays,
  rMonths,
  rSeasons,
  rZodiac,
  rEnd,
  canvas,
}) => {
  const theme = useTheme();
  const bg = theme.palette.background.default;
  const fg = theme.palette.secondary.main;

  const [positions, setPositions] = useState<Celestials>({
    sun: { ecliptic: { lat: 0, lon: 0 } },
    jupiter: { ecliptic: { lat: 0, lon: 0 } },
    mars: { ecliptic: { lat: 0, lon: 0 } },
    mercury: { ecliptic: { lat: 0, lon: 0 } },
    moon: { ecliptic: { lat: 0, lon: 0 } },
    neptune: { ecliptic: { lat: 0, lon: 0 } },
    pluto: { ecliptic: { lat: 0, lon: 0 } },
    saturn: { ecliptic: { lat: 0, lon: 0 } },
    uranus: { ecliptic: { lat: 0, lon: 0 } },
    venus: { ecliptic: { lat: 0, lon: 0 } },
  });
  const [intersections, setIntersections] = useState<Intersections>({
    east: 0.0,
    west: 0.0,
  });

  // Play
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setIntersections((prev) => ({
  //       east: (prev.east + 0.2) % 360,
  //       west: (prev.west + 0.2) % 360,
  //     }));
  //   }, 100);
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(
      async () => {
        try {
          const date = new Date().toISOString();
          const data = await fetchEclipticPositions(date);
          setPositions(data.positions);
          setIntersections(data.intersections);
        } catch (err) {
          console.error(err);
        }
      },
      5 * 60 * 1000,
    );

    // Initial fetch
    (async () => {
      try {
        const date = new Date().toISOString();
        const data = await fetchEclipticPositions(date);
        setPositions(data.positions);
        setIntersections(data.intersections);
      } catch (err) {
        console.error(err);
      }
    })();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${canvas[0]} ${canvas[1]}`}
      >
        <Horizon
          rCelestials={rCelestials}
          rEnd={rEnd}
          east={intersections.east}
          west={intersections.west}
          sun={positions.sun.ecliptic.lon}
          moon={positions.moon.ecliptic.lon}
          canvas={canvas}
        />
        <g
          transform={`rotate(${-intersections.west - 180} ${canvas[0] / 2} ${canvas[1] / 2})`}
        >
          <g>
            <circle
              key={`end-circle`}
              fill="none"
              strokeWidth="1"
              stroke={fg}
              cx="50%"
              cy="50%"
              r={rEnd}
            />
          </g>
          <CelestialCircle
            r={rCelestials}
            celestials={positions}
            west={intersections.west}
            fg={fg}
            bg={bg}
            canvas={canvas}
          />
          <DayCircle
            rStart={rDays}
            rEnd={rMonths}
            fg={fg}
            bg={bg}
            canvas={canvas}
          />
          <MonthCircle
            rStart={rMonths}
            rEnd={rSeasons}
            fg={fg}
            bg={bg}
            canvas={canvas}
          />
          <SeasonCircle
            rStart={rSeasons}
            rEnd={rZodiac}
            fg={fg}
            bg={bg}
            canvas={canvas}
          />
          <ZodiacCircle
            rStart={rZodiac}
            rEnd={rEnd}
            canvas={canvas}
            fg={fg}
            bg={bg}
          />
        </g>
      </svg>
    </React.Fragment>
  );
};
