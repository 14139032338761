import React from "react";
import { PlanetSVGProps } from "./../types";

const MercuryLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="M212.1914062,132.7773438c0-27.3691406-17.777832-50.6484375-42.3867188-58.9396973
	c19.246582-7.7109375,26.9887695-24.9230957,27.3955078-25.857666l-6.3637695-2.7717285
	C190.7280273,45.4624023,179.4472656,70.5856934,150,70.5856934c-29.3012695,0-40.3823242-24.348877-40.8364258-25.3774414
	l-6.3637695,2.7717285c0.4067383,0.9345703,8.1508789,18.1464844,27.3964844,25.8574219
	c-24.609375,8.2912598-42.3876953,31.5703125-42.3876953,58.9399414c0,33.1279297,26.0371094,60.2839355,58.7216797,62.0910645
	V225.375h-19.4472656v6.9399414h19.4472656v22.4768066h6.9399414v-22.4768066h19.4506836V225.375h-19.4506836v-30.5065918
	C186.1542969,193.0615234,212.1914062,165.9052734,212.1914062,132.7773438z M94.7485352,132.7773438
	c0-30.4655762,24.7841797-55.251709,55.2514648-55.251709s55.2519531,24.7861328,55.2519531,55.251709
	c0,30.467041-24.784668,55.2531738-55.2519531,55.2531738S94.7485352,163.2443848,94.7485352,132.7773438z"
        />
      </svg>
    </g>
  );
};

export default MercuryLine;
