import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Aquarius: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M60.3469772,141.3134155c-7.6718597,0-14.9031982-2.8481445-20.5418816-8.1344147
			c-6.0487061-5.6691742-9.5186653-13.6731262-9.5186653-21.9583282h6.9399147
			c0,6.4689026,2.5990829,12.4684677,7.322834,16.8957214c4.7169762,4.4221649,10.8537865,6.6163025,17.3769035,6.2045746
			c12.1143532-0.7844696,21.60252-11.3315811,21.60252-24.0118332V92.8559875c0-8.285202,3.4665756-16.2891541,9.5152817-21.9566422
			c6.1300354-5.7471161,14.1272049-8.6122017,22.5716705-8.0700226
			c15.7537537,1.0216713,28.0951385,14.6100845,28.0951385,30.9382019v17.4531479
			c0,6.4689026,2.5990906,12.4701614,7.3228455,16.8957214c4.7169647,4.4204712,10.8639526,6.6281586,17.3768921,6.2045746
			c12.1143646-0.7844696,21.6025238-11.3315811,21.6025238-24.0118332V92.8559875
			c0-8.285202,3.466568-16.2891541,9.5152893-21.9566422c6.1334229-5.7471161,14.1204224-8.6122017,22.5716705-8.0700226
			c15.7537384,1.0216713,28.0951385,14.6100845,28.0951385,30.9382019v21.0840683
			c0,10.7639847,8.7562103,19.5202179,19.5185089,19.5202179v6.9399109c-14.5880432,0-26.4584198-11.8703766-26.4584198-26.4601288
			V93.7675247c0-12.6802521-9.4881744-23.2273712-21.6025238-24.0118408
			c-6.4655151-0.4168015-12.6565399,1.7841187-17.3769073,6.2062836
			c-4.7237549,4.4255524-7.3228302,10.4251251-7.3228302,16.8940201v17.4531479
			c0,16.3281174-12.3414001,29.9165268-28.0951538,30.9381943c-8.4105835,0.5472717-16.4382477-2.3178101-22.5716705-8.0666351
			c-6.0453186-5.6691742-9.515274-13.6731186-9.515274-21.960022V93.7675247
			c0-12.6802521-9.4881668-23.2273712-21.6025238-24.0118408c-6.4451752-0.4168015-12.6599274,1.7841187-17.3768997,6.2062836
			c-4.7237549,4.4255524-7.3228378,10.4251251-7.3228378,16.8940201v17.4531479
			c0,16.3281174-12.3413925,29.9165268-28.0951424,30.9381943
			C61.6956558,141.2913818,61.0213165,141.3134155,60.3469772,141.3134155z"
      />
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M60.3503685,237.2353973c-7.675251,0-14.9099808-2.8481445-20.5486603-8.1343994
			c-6.0487099-5.6691895-9.5152779-13.6714325-9.5152779-21.9566345h6.9399147
			c0,6.4671936,2.5990829,12.4667664,7.322834,16.8940125c4.7135849,4.4204712,10.850399,6.6366425,17.3769035,6.2062836
			c12.1143532-0.7844696,21.60252-11.3315887,21.60252-24.0118408v-17.4531555
			c0-8.2868958,3.4699631-16.2908325,9.5152817-21.960022c6.1368103-5.7488098,14.161087-8.6172943,22.5716705-8.0666351
			c15.7537537,1.0216675,28.0951385,14.6100922,28.0951385,30.9382019v17.4531555
			c0,6.4688873,2.5990906,12.4684601,7.3228455,16.8940125c4.7169647,4.4204712,10.853775,6.6366425,17.3768921,6.2062836
			c12.1143646-0.7844696,21.6025238-11.3315887,21.6025238-24.0118408v-17.4531555
			c0-8.2868958,3.4699707-16.2908325,9.5152893-21.960022c6.1368103-5.7488098,14.1678619-8.6172943,22.5716705-8.0666351
			c15.7537384,1.0216675,28.0951385,14.6100922,28.0951385,30.9382019v21.0840759
			c0,10.7639771,8.7562103,19.5202026,19.5185089,19.5202026v6.9399109c-14.5880432,0-26.4584198-11.8703613-26.4584198-26.4601135
			v-21.0840759c0-12.6802521-9.4881744-23.2273712-21.6025238-24.0118408
			c-6.49263-0.4151001-12.6599274,1.7824249-17.3769073,6.2045898c-4.7237549,4.4255524-7.3228302,10.4268188-7.3228302,16.8957062
			v17.4531555c0,16.328125-12.3414001,29.9165344-28.0951538,30.9382019
			c-8.4309082,0.5404816-16.4416351-2.3229065-22.5716705-8.0700226c-6.0487061-5.6674957-9.515274-13.6714325-9.515274-21.9566345
			v-17.4531555c0-12.6802521-9.4881668-23.2273712-21.6025238-24.0118408
			c-6.4892273-0.4151001-12.6599274,1.7824249-17.3768997,6.2045898
			c-4.7237549,4.4255524-7.3228378,10.4268188-7.3228378,16.8957062v17.4531555
			c0,16.328125-12.3413925,29.9165344-28.0951424,30.9382019C61.6956558,237.2150726,61.0213165,237.2353973,60.3503685,237.2353973
			z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Aquarius;
