import React, { createContext, useContext, useState, useEffect } from "react";
import { loginUser, refreshToken, logoutUser } from "./../services/authService";

interface AuthContextProps {
  user: string | null;
  token: string | null;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const login = async (
    username: string,
    password: string,
  ): Promise<boolean> => {
    const response = await loginUser(username, password);
    if (response) {
      setToken(response.token);
      setUser(username);
      return true;
    }
    return false;
  };

  const logout = () => {
    logoutUser(token);
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (token) {
          refreshToken(token).then((newToken) => setToken(newToken));
        }
      },
      4 * 60 * 1000,
    );
    return () => clearInterval(interval);
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error("useAuthContext must be used within AuthContextProvider");
  return context;
};
