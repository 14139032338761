import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Capricorn: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M230.5541992,163.6494141c-15.7324219,0-29.5693359,8.241333-37.4472656,20.6265869V91.3665771
	c0-24.1203613-19.6240234-43.7421875-43.7402344-43.7421875c-18.0654297,0-33.6074219,11.0097656-40.2714844,26.6721191
	c-6.6630859-15.6623535-22.2050781-26.6721191-40.2695312-26.6721191c-24.1210938,0-43.7441406,19.6218262-43.7441406,43.7421875
	h6.9394531c0-20.2928467,16.5097656-36.8022461,36.8046875-36.8022461c20.2910156,0,36.7998047,16.5093994,36.7998047,36.8022461
	v130.4555664h6.9404297V91.3665771c0-20.2928467,16.5087891-36.8022461,36.8007812-36.8022461
	c20.2910156,0,36.7998047,16.5093994,36.7998047,36.8022461v116.6451416h0.0273438
	c0,15.7172852,8.2226562,29.5412598,20.5839844,37.4239502h-58.5673828v6.9399414h82.34375
	c24.4628906,0,44.3642578-19.9013672,44.3642578-44.3638916
	C274.918457,183.5509033,255.0170898,163.6494141,230.5541992,163.6494141z M230.5541992,245.4356689
	c-20.6328125,0-37.4199219-16.7888184-37.4199219-37.4239502s16.7871094-37.4223633,37.4199219-37.4223633
	c20.6367188,0,37.4238281,16.7872314,37.4238281,37.4223633S251.190918,245.4356689,230.5541992,245.4356689z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Capricorn;
