import React from "react";
import { PlanetSVGProps } from "./../types";

const NeptuneLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="150"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="m 234.12847,84.110363 -7.11984,-9.344455 -7.11895,-9.344454 -7.11984,9.344454 -7.11894,9.344455 h 11.07994 v 8.933591 c 0,35.734806 -28.23835,64.992756 -63.57252,66.648656 V 84.110363 h 11.07994 l -7.11983,-9.344455 -7.11895,-9.344454 -7.11984,9.344454 -7.11895,9.344455 h 11.07817 V 159.69261 C 111.50469,158.03671 83.269891,128.77876 83.269891,93.043955 v -8.933592 h 11.07817 l -7.119838,-9.344455 -7.118949,-9.344454 -7.119839,9.344454 -7.118949,9.344455 h 11.079059 v 8.933592 c 0,39.220025 31.070805,71.306875 69.889315,72.968555 v 60.38612 h -19.13889 v 6.3199 h 19.13889 v 22.29996 h 6.31946 v -22.29996 h 19.14157 v -6.3199 h -19.14157 v -60.38612 c 38.8185,-1.66168 69.89198,-33.74831 69.89198,-72.968555 v -8.933592 z"
        />
      </svg>
    </g>
  );
};

export default NeptuneLine;
