import React from "react";
import { PlanetSVGProps } from "./../types";

const MarsLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="m 186.99594,70.997005 v 6.939694 H 217.1561 L 177.87192,117.2199 c -13.55371,-12.35815 -30.97656,-19.12573 -49.43848,-19.12573 -19.62403,0 -38.075202,7.64111 -51.951178,21.51953 -28.644532,28.64552 -28.644532,75.25807 0,103.90382 14.323242,14.32544 33.133788,21.48559 51.951178,21.48559 18.80957,0 37.63086,-7.16357 51.95117,-21.48559 13.87989,-13.87647 21.52051,-32.32764 21.52051,-51.95288 0,-18.46265 -6.76758,-35.88478 -19.12695,-49.43824 l 39.2832,-39.281254 v 30.158934 h 6.93945 V 70.997005 Z M 175.47739,218.61078 c -25.93946,25.94336 -68.14845,25.93994 -94.088878,0 -25.939453,-25.94165 -25.939453,-68.14893 0,-94.09034 12.565429,-12.5669 29.274418,-19.48633 47.044928,-19.48633 17.76953,0 34.47949,6.91943 47.04395,19.48633 12.56836,12.56666 19.48828,29.27418 19.48828,47.0442 0,17.77172 -6.91992,34.47924 -19.48828,47.04614 z"
        />
      </svg>
    </g>
  );
};

export default MarsLine;
