import React from "react";
import { PlanetSVGProps } from "./../types";

const PlutoLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="150"
          id="circle1"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="m 235.52075,105.15479 h -6.93994 c 0,43.32861 -35.25195,78.58056 -78.58252,78.58056 -43.32715,0 -78.578612,-35.25195 -78.578612,-78.58056 h -6.94043 c 0,45.99243 36.497562,83.60351 82.049312,85.43261 v 33.64063 h -21.38232 v 6.93994 h 21.38232 v 24.66577 h 6.93995 v -24.66577 h 21.38183 v -6.93994 H 153.46851 V 190.5874 c 45.55175,-1.8291 82.05224,-39.43994 82.05224,-85.43261 z"
        />
        <path
          stroke={bg}
          strokeWidth={10}
          d="m 149.99976,164.45738 c 33.62891,0 60.98877,-27.35962 60.98877,-60.98852 0,-33.628913 -27.35986,-60.988532 -60.98877,-60.988532 -33.62842,0 -60.988287,27.359619 -60.988287,60.988532 0,33.6289 27.359867,60.98852 60.988287,60.98852 z m 0,-115.037111 c 29.80322,0 54.04883,24.245606 54.04883,54.048591 0,29.80297 -24.24561,54.04882 -54.04883,54.04882 -29.80273,0 -54.048346,-24.24585 -54.048346,-54.04882 0,-29.802985 24.245616,-54.048591 54.048346,-54.048591 z"
        />
      </svg>
    </g>
  );
};

export default PlutoLine;
