import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Aries: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M224.737793,44.166275c-41.9121094,0-66.2329102,41.5109863-74.7392578,129.3044434
	C141.4916992,85.6772614,117.171875,44.166275,75.262207,44.166275c-34.0390625,0-45.8413086,30.4162598-45.956543,30.7230225
	l6.4956055,2.4449463c0.4067383-1.0708008,10.3149414-26.2280273,39.4609375-26.2280273
	c63.8789062,0,71.2661133,116.8416748,71.2661133,204.7275391h6.9399414c0-87.8858643,7.387207-204.7275391,71.2695312-204.7275391
	c29.1459961,0,39.0541992,25.1572266,39.4609375,26.2280273l6.4956055-2.4449463
	C270.5791016,74.5825348,258.7768555,44.166275,224.737793,44.166275z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Aries;
