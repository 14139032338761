import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Pisces: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  if (thetaEnd < thetaStart) {
    thetaStart = thetaStart + 2 * Math.PI;
  }

  const theta = ((thetaStart + thetaEnd) % 360) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M195.8212891,153.4699097h48.2216797v-6.9398193h-48.2207031
	c1.1884766-44.5395508,25.0029297-83.4117432,60.0302734-97.40625l-2.5761719-6.4451904
	c-37.6259766,15.0343018-63.1904297,56.4938965-64.3935547,103.8514404h-77.7666016
	c-1.203125-47.3323975-26.7675781-88.7749023-64.3935547-103.807373l-2.5751953,6.4450684
	c35.0263672,13.9926758,58.8408203,52.8464355,60.0302734,97.3623047H55.9570312v6.9398193h48.2207031
	c-1.1738281,44.5479736-24.9921875,83.4061279-60.0273438,97.3604736l2.5693359,6.4486084
	c37.640625-14.9942627,63.2089844-56.4412842,64.3974609-103.809082h77.7646484
	c1.1894531,47.348999,26.7578125,88.8126221,64.3945312,103.8514404l2.5761719-6.4451904
	C220.8144531,236.8772583,196.9960938,198.0026245,195.8212891,153.4699097z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Pisces;
