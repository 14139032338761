import React, { createContext, useState, useContext, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

interface ThemeContextProps {
  toggleTheme: () => void;
  mode: "light" | "dark";
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [mode, setMode] = useState<"light" | "dark">(
    () => (localStorage.getItem("theme") as "light" | "dark") || "light",
  );

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  // Create a customized MUI theme
  const theme = createTheme({
    palette: {
      mode: mode,
      ...(mode === "light"
        ? {
            primary: { main: "#1976d2" },
            secondary: { main: "#000000" },
            background: { default: "#f0f0f0" },
          }
        : {
            primary: { main: "#90caf9" },
            secondary: { main: "#ffffff" },
            background: { default: "#121212" },
          }),
    },
    typography: {
      fontFamily: '"Overlock", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 12,
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          },
          "#root": {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          },
          main: {
            flexGrow: 1,
            minHeight: "100vh",
            paddingTop: "50px",
          },
          a: {
            textDecoration: "none",
            color: mode === "light" ? "#1976d2" : "#90caf9",
            "&:hover": {
              textDecoration: "underline",
              color: mode === "light" ? "#1565c0" : "#82b1ff",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
          },
          gutters: {
            margin: 0,
            padding: 0,
          },
          regular: {
            margin: 0,
            padding: 0,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: "transparent",
            boxShadow: "none",
            borderBottom: `1px solid ${mode === "light" ? "#e0e0e0" : "#333"}`,
            color: `${mode === "light" ? "#333" : "#e0e0e0"}`,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem",
            padding: "6px 12px",
            textTransform: "none",
            "&:hover": {
              backgroundColor:
                mode === "light"
                  ? "rgba(25, 118, 210, 0.1)"
                  : "rgba(144, 202, 249, 0.1)",
              boxShadow: "none",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "transparent",
              borderBottom: `3px solid ${mode === "light" ? "#1976d2" : "#90caf9"}`,
            },
            "&:hover": {
              backgroundColor: mode === "light" ? "#f5f5f5" : "#333",
            },
          },
        },
      },
    },
  });

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, mode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
