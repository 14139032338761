import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Saghittarius: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <polygon
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        points="132.5246582,42.9395752 132.5246582,49.8795166 245.213623,49.8795166 137.8864746,157.2067871
	99.7736816,119.0939941 94.8669434,124.0007324 132.9797363,162.1135254 42.9396973,252.1536865 47.8464355,257.0604248
	137.8864746,167.0202637 176.0007324,205.1347656 180.9074707,200.2280273 142.7932129,162.1135254 250.1203613,54.7862549
	250.1203613,167.4769287 257.0603027,167.4769287 257.0603027,42.9395752 "
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Saghittarius;
