import React from "react";
import {
  gradientPicker,
  angularDist,
  createSeededRandom,
  toHex,
} from "./Utils";

const NIGHT_SKY_COLOR = "#101821";
const SUNRISE_COLOR = "#f24b27";
const SUNSET_COLOR = "#bf2b14";
const BLUE_SKY_COLOR = "#7a9fb2";
const SUN_SHINE_COLOR = "#ffd700";
const MOON_SHINE_COLOR = "#fbfadb";

const getHorizonAndSunColors = (
  sunToEastHorizon: number,
  isDay: boolean,
): { [key: string]: string } => {
  let west = "";
  let east = "";

  if (sunToEastHorizon < 18) {
    east = gradientPicker(
      SUNRISE_COLOR,
      isDay ? BLUE_SKY_COLOR : NIGHT_SKY_COLOR,
      sunToEastHorizon,
      [0, 18],
    );
    west = gradientPicker(
      BLUE_SKY_COLOR,
      isDay ? BLUE_SKY_COLOR : NIGHT_SKY_COLOR,
      sunToEastHorizon,
      [0, 18],
    );
  } else if (sunToEastHorizon > 18 && sunToEastHorizon < 180 - 18) {
    east = west = isDay ? BLUE_SKY_COLOR : NIGHT_SKY_COLOR;
  } else {
    west = gradientPicker(
      SUNSET_COLOR,
      isDay ? BLUE_SKY_COLOR : NIGHT_SKY_COLOR,
      180 - sunToEastHorizon,
      [0, 18],
    );
    east = gradientPicker(
      NIGHT_SKY_COLOR,
      isDay ? BLUE_SKY_COLOR : NIGHT_SKY_COLOR,
      180 - sunToEastHorizon,
      [0, 18],
    );
  }

  return { west, east };
};

const generateStars = (max_r: number, count: number) => {
  const random = createSeededRandom(5);

  const stars = [];
  for (let i = 0; i < count; i++) {
    const r = 50 + (max_r - 50) * random();
    const theta = random() * Math.PI;

    const x = r * Math.cos(theta);
    const y = r * Math.sin(theta);

    const duration = 1 + random() * 2;
    const delay = random() * 5;
    const size = 0.5 + random();

    stars.push({
      position: `radial-gradient(circle, white ${size}px, transparent ${size}px) ${x}px ${y}px`,
      animation: `${duration}s infinite alternate ease-in-out ${delay}s`,
    });
  }
  return stars;
};

interface Props {
  rCelestials: number;
  rEnd: number;
  east: number;
  west: number;
  sun: number;
  moon: number;
  canvas: number[];
}

const Horizon: React.FC<Props> = ({
  rCelestials,
  rEnd,
  east,
  west,
  sun,
  moon,
  canvas,
}) => {
  const random = createSeededRandom(5);

  const sunToWestHorizon = angularDist(sun, west);
  const sunToEastHorizon = angularDist(sun, east);
  const moonToWestHorizon = angularDist(moon, west);
  const sunToMoon = angularDist(sun, moon);

  // Correct coordinates (Astrolabe coordinate starts from 03:00, CSS coordinate starts from 00:00)
  west += 90;
  sun += 90;
  moon += 90;
  east += 90;

  if (west > east) east += 360;

  // Change to the west coordinate
  const base = west;
  const end = east - west;
  sun -= west;
  if (sun < 0) sun += 360;
  moon -= west;
  if (moon < 0) moon += 360;

  const isDay = sun < end;
  const isNight = !isDay;
  const isMoonVisible = moon < end;

  const { west: horizonWest, east: horizonEast } = getHorizonAndSunColors(
    sunToEastHorizon,
    isDay,
  );

  const sunToHorizon = Math.min(sunToWestHorizon, 180 - sunToWestHorizon);
  const moonToHorizon = Math.min(moonToWestHorizon, 180 - moonToWestHorizon);

  const stars = generateStars(rCelestials, 30);
  const starsOpacity = sunToHorizon < 18 ? sunToHorizon / 18 : 1;

  const moonMaxBrightness = (sunToMoon / 180) * 50;
  const moonBrightness = isMoonVisible
    ? toHex(moonMaxBrightness)
    : !isMoonVisible && moonToHorizon < 18
      ? toHex(moonMaxBrightness * (1 - moonToHorizon / 18))
      : toHex(0);

  const sunBrightness = toHex(
    isNight && sunToHorizon < 18
      ? ((18 - sunToHorizon) / 18) * 50
      : isNight
        ? 0
        : 50,
  );
  const moonWideness = (sunToMoon / 180) * 60;

  const gradientEnd = (rEnd / canvas[0]) * 100;

  return (
    <foreignObject width={canvas[0]} height={canvas[1]}>
      <div
        style={{
          width: `${canvas[0]}px`,
          height: `${canvas[1]}px`,
          borderRadius: "50%",
          opacity: 1,
          maskImage: `radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) ${gradientEnd}%)`,
          WebkitMaskImage: `radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) ${gradientEnd}%)`,
          clipPath: `polygon(0 0%, 100% 0%, 100% 50%, 0% 50%)`,
          background: `
          conic-gradient(
            from ${sun - 180}deg,
            #00000000 35deg,
            ${SUN_SHINE_COLOR}${sunBrightness} 90deg,
            #00000000 145deg
          ),
          conic-gradient(
            from ${moon - 180}deg,
            #00000000 ${90 - moonWideness}deg,
            ${MOON_SHINE_COLOR}${moonBrightness} 90deg,
            #00000000 ${90 + moonWideness}deg
          ),
          conic-gradient(
            from ${base - west - 90}deg,
            ${horizonWest} 0deg,
            ${horizonEast} ${end}deg,
            #00000000 ${end}deg
          )
          `,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: `${canvas[0]}px`,
          height: `${canvas[1] / 2}px`,
          pointerEvents: "none",
          borderRadius: "50%",
          opacity: isNight ? starsOpacity : 0,
          visibility: isNight ? "visible" : "hidden",
          transition: "opacity 1s ease-in-out, visibility 0s linear 1s",
        }}
      >
        {stars.map((star, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              width: `${canvas[0]}px`,
              height: `${canvas[1] / 2}px`,
              background: star.position,
              backgroundSize: `${canvas[0]}px ${canvas[1] / 2}px`,
              animation: random() < 0.75 ? `twinkle ${star.animation}` : "",
            }}
          ></div>
        ))}
      </div>

      <style>
        {`@keyframes twinkle { 0% { opacity: 0.3; } 50% { opacity: 1; } 100% { opacity: 0.3; } }`}
      </style>
    </foreignObject>
  );
};

export default Horizon;
