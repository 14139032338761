export const toRad = (x: number) => (x * Math.PI) / 180;
export const toDeg = (x: number) => (x * 180) / Math.PI;
export const calcX = (r: number, theta: number, canvas: number[]): number =>
  r * Math.cos(theta) + canvas[0] / 2;
export const calcY = (r: number, theta: number, canvas: number[]): number =>
  r * Math.sin(theta) + canvas[1] / 2;

export const angularDist = (angle1: number, angle2: number): number => {
  const diff = Math.abs((angle1 - angle2) % 360);
  return Math.min(diff, 360 - diff);
};

export const circlePath = (
  r: number,
  id: string,
  color: string,
  canvas: number[],
) => (
  <path
    id={id.toString()}
    stroke={color}
    d={`M ${canvas[0] / 2}, ${canvas[1] / 2} m ${-r}, 0 a ${r}, ${r} 0 1,1 ${2 * r}, 0 a ${r}, ${2 * r} 0 1,1 ${-2 * r}, 0`}
  />
);

export const createSeededRandom = (seed: number) => {
  let s = seed % 2147483647;
  if (s <= 0) s += 2147483646;

  return () => {
    s = (s * 16807) % 2147483647;
    return (s - 1) / 2147483646;
  };
};

export const toHex = (value: number, min: number = 0, max: number = 100) => {
  if (value < min || value >= max) {
    throw new RangeError(
      `Value must be between ${min} and ${max}. value: ${value}.`,
    );
  }

  const scaledValue = Math.round(((value - min) / (max - min)) * 255);
  return scaledValue.toString(16).padStart(2, "0").toUpperCase();
};

export const gradientPicker = (
  color1: string,
  color2: string,
  value: number,
  range: number[],
): string => {
  const t = (value - range[0]) / (range[1] - range[0]);

  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const interpolate = (start: number, end: number, t: number): number =>
    Math.round(start + (end - start) * t);

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const r = interpolate(rgb1.r, rgb2.r, t);
  const g = interpolate(rgb1.g, rgb2.g, t);
  const b = interpolate(rgb1.b, rgb2.b, t);

  const rgbToHex = (r: number, g: number, b: number): string =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

  return rgbToHex(r, g, b);
};

export const drawTiltedTick = (
  r1: number,
  r2: number,
  theta: number,
  color: string,
  key: string,
  canvas: number[],
) => {
  const x1 = calcX(r1, theta, canvas);
  const x2 = calcX(r2, theta, canvas);
  const y1 = calcY(r1, theta, canvas);
  const y2 = calcY(r2, theta, canvas);

  return (
    <line
      key={key}
      x1={x1}
      x2={x2}
      y1={y1}
      y2={y2}
      stroke={color}
      strokeWidth={1}
    />
  );
};
