import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from "react-markdown";

interface InfoCardProps {
  open: boolean;
  onClose: () => void;
  image: string;
  title: string;
  description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({
  open,
  onClose,
  image,
  title,
  description,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      style={{ overflow: "hidden" }}
    >
      <Card style={{ overflow: "hidden" }}>
        <div
          style={{ position: "relative", height: "400px", overflow: "hidden" }}
        >
          <img
            src={image}
            alt={title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h3"
            style={{
              position: "absolute",
              bottom: 8,
              left: 16,
              color: "#fff",
              fontFamily: "Overlock SC",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {title}
          </Typography>
        </div>
        <CardContent>
          <ReactMarkdown>{description}</ReactMarkdown>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default InfoCard;
