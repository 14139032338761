import React from "react";
import { PlanetSVGProps } from "./../types";

const SaturnLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="150"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="m 212.21758,215.37306 c 0,11.91104 -9.69176,21.60279 -21.60279,21.60279 -0.59812,0 -1.18993,-0.0234 -1.77542,-0.0704 -5.99747,-0.4867 -11.45094,-3.69383 -14.96029,-8.79953 -3.77188,-5.48775 -4.86167,-12.28858 -2.99242,-18.66202 l 19.63431,-66.94533 c 3.73398,-12.73762 1.51199,-26.3032 -6.10122,-37.21894 -7.25325,-10.399259 -18.52918,-16.846221 -30.9382,-17.683863 -0.97702,-0.06563 -1.96577,-0.100138 -2.95813,-0.100138 -15.57826,0 -29.2575,8.273579 -36.88605,20.654631 V 70.239408 h 17.76235 V 63.828307 H 113.63737 V 47.849044 h -6.41066 V 63.828307 H 89.461658 v 6.411101 H 107.22671 V 240.1814 h 6.41066 V 130.79235 c 0,-20.3382 16.54806,-36.88559 36.88605,-36.88559 0.84801,0 1.69332,0.02819 2.52601,0.08458 10.45858,0.707508 19.97531,6.15918 26.11081,14.95713 6.49545,9.31127 8.39267,20.88287 5.20629,31.74697 l -19.63431,66.94533 c -2.41324,8.22983 -1.00499,17.0138 3.8666,24.09812 4.60455,6.70047 11.79465,10.91236 19.72091,11.55582 0.75419,0.0625 1.52192,0.0922 2.29506,0.0922 15.44474,0 28.01344,-12.56689 28.01344,-28.01389 z"
        />
      </svg>
    </g>
  );
};

export default SaturnLine;
