import React from "react";
import { PlanetSVGProps } from "./../types";

const VenusLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="M223.4707031,117.6367188c0-40.5109863-32.9614258-73.470459-73.4726562-73.470459
	c-40.5107422,0-73.46875,32.9594727-73.46875,73.470459c0,39.3474121,31.0932617,71.5639648,69.9990234,73.3828125v33.3862305
	h-21.0166016v6.9399414h21.0166016v24.4880371h6.9399414v-24.4880371h21.0195312v-6.9399414h-21.0195312v-33.3862305
	C192.3740234,189.2006836,223.4707031,156.9841309,223.4707031,117.6367188z M83.4692383,117.6367188
	c0-36.6853027,29.84375-66.5305176,66.5288086-66.5305176c36.6855469,0,66.5322266,29.8452148,66.5322266,66.5305176
	c0,36.6855469-29.8466797,66.5307617-66.5322266,66.5307617C113.3129883,184.1674805,83.4692383,154.3222656,83.4692383,117.6367188z"
        />
      </svg>
    </g>
  );
};

export default VenusLine;
