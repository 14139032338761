import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Gemini: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M208.6381836,216.8626709V83.1373291c14.9663086-8.8059082,27.0522461-21.0084229,34.5136719-35.6621094
	l-6.1879883-3.1479492c-14.6352539,28.7490234-48.7724609,47.3255615-86.9658203,47.3255615
	c-38.1899414,0-72.3266602-18.5765381-86.9692383-47.3255615l-6.1806641,3.1479492
	C64.3105469,62.12854,76.3964844,74.3309326,91.3613281,83.1368408v133.7263184
	c-14.9648438,8.8059082-27.0507812,21.0083008-34.5131836,35.6616211l6.1806641,3.1479492
	c14.6425781-28.7491455,48.7792969-47.3256836,86.9692383-47.3256836c38.1933594,0,72.3305664,18.5765381,86.9658203,47.3256836
	l6.1879883-3.1479492C235.6904297,237.8710938,223.6044922,225.6685791,208.6381836,216.8626709z M98.3012695,213.1339111
	V86.8660889c15.3012695,7.5213623,33.0551758,11.7266846,51.6967773,11.7266846
	c18.6430664,0,36.3984375-4.2052002,51.7006836-11.7264404v126.267334
	c-15.3022461-7.5212402-33.0576172-11.7264404-51.7006836-11.7264404
	C131.3564453,201.4072266,113.6025391,205.6124268,98.3012695,213.1339111z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Gemini;
