import React, { FC, useState } from "react";
import { toRad, circlePath, drawTiltedTick } from "./Utils";
import InfoCard from "../InfoCard";

interface SeasonType {
  label: string;
  start: number;
  image: string;
  description: string;
}

const SEASONS: SeasonType[] = [
  {
    label: "Spring",
    start: 0,
    image: "/data/calendar/spring.jpg",
    description: `## A Time of Renewal

**Spring** in the Northern Hemisphere typically spans from the **March equinox** (around March 20th) to the **June solstice** (around June 21st). It's a season marked by warming temperatures, blossoming flowers, and the awakening of wildlife after winter's dormancy. Astronomically, it's when the Earth's tilt begins to lean more towards the Sun, resulting in longer days and shorter nights.`,
  },
  {
    label: "Summer",
    start: -90,
    image: "/data/calendar/summer.jpg",
    description: `## The Warm Embrace

**Summer** lasts from the **June solstice** (around June 21st) to the **September equinox** (around September 22nd). This season brings the warmest temperatures of the year, abundant sunshine, and lush vegetation. The Northern Hemisphere is tilted most directly toward the Sun, leading to the longest days and shortest nights.`,
  },
  {
    label: "Fall",
    start: -180,
    image: "/data/calendar/fall.jpg",
    description: `## The Season of Change

**Fall**, also known as **Autumn**, extends from the **September equinox** (around September 22nd) to the **December solstice** (around December 21st). It's characterized by cooler temperatures, the changing colors of leaves, and the harvest of many crops. Astronomically, days begin to shorten as the Earth's tilt moves away from the Sun.`,
  },
  {
    label: "Winter",
    start: -270,
    image: "/data/calendar/winter.jpg",
    description: `## A Time of Rest

**Winter** spans from the **December solstice** (around December 21st) to the **March equinox** (around March 20th). It's the coldest season, often bringing snow, ice, and shorter days. The Northern Hemisphere is tilted away from the Sun, resulting in the least direct sunlight and the longest nights of the year.`,
  },
];

interface Props {
  rStart: number;
  rEnd: number;
  fg: string;
  bg: string;
  canvas: number[];
}

const SeasonCircle: FC<Props> = ({ rStart, rEnd, fg, bg, canvas }) => {
  const [selectedSeason, setSelectedSeason] = useState<null | SeasonType>(null);

  const handleOpen = (season: SeasonType) => {
    setSelectedSeason(season);
  };

  const handleClose = () => {
    setSelectedSeason(null);
  };

  return (
    <>
      <circle
        key={`season-circle`}
        fill="none"
        strokeWidth="1"
        stroke={fg}
        cx="50%"
        cy="50%"
        r={rStart}
      />
      <g>
        <defs>
          {circlePath((rStart + rEnd) / 2 - 5, "seasons-circle", fg, canvas)}
        </defs>
        {SEASONS.map((season) =>
          drawTiltedTick(
            rStart,
            rEnd,
            toRad(season.start),
            fg,
            `season-tick-${season.start}`,
            canvas,
          ),
        )}
        {SEASONS.map((season, index) => (
          <text
            key={`season-circle-${season}-${index}`}
            fill={fg}
            fontSize="16px"
            onClick={() => handleOpen(season)}
            cursor={"pointer"}
            transform={`rotate(${season.start - 180 + 1} ${canvas[0] / 2} ${canvas[1] / 2})`}
          >
            <textPath xlinkHref="#seasons-circle">{season.label}</textPath>
          </text>
        ))}
        {selectedSeason && (
          <InfoCard
            open={!!selectedSeason}
            onClose={handleClose}
            image={selectedSeason.image}
            title={selectedSeason.label}
            description={selectedSeason.description}
          />
        )}
      </g>
    </>
  );
};

export default SeasonCircle;
