import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Link } from "@mui/material";
import { useAuthContext } from "../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuthContext();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const success = await login(email, password);
      if (success) {
        toast.success("Login successful!", {
          position: "top-right",
        });
        navigate("/");
      } else {
        toast.error("Invalid credentials. Please try again.", {
          position: "top-right",
        });
      }
    } catch (error: any) {
      toast.error(error.message || "An unexpected error occurred.", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box width="100%" maxWidth={400} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Login
            </Button>
            <Box mt={2}>
              <Typography variant="body2">
                Don't have an account?{" "}
                <Link href="/register" underline="hover" color="primary">
                  Register here
                </Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default Login;
