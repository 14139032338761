import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Ophiochus: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={20}
        fillOpacity={0}
        d="m 83.635347,36.78124 -0.03984,150.97157 c 0,0 -0.678575,61.44658 64.382023,66.40449 66.40693,-3.56885 68.48281,-64.01751 68.48281,-64.01751 l 0.0233,-153.398217"
      />
      <path
        stroke={fg}
        strokeWidth={20}
        d="m 25.06931,136.39455 c 0,0 17.766195,-42.147762 58.071947,-41.887959 57.755853,0.372283 74.575433,52.286489 132.962543,51.725289 41.26902,-0.39666 57.43728,-39.98397 57.43728,-39.98397"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Ophiochus;
