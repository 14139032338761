import React from "react";
import { PlanetSVGProps } from "./../types";

const SunLine: React.FC<PlanetSVGProps> = ({ x, y, width, height, fg, bg }) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{
            fill: `${fg}`,
            stroke: `${bg}`,
            strokeWidth: 10,
          }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="M150,42.4313965C90.6855469,42.4313965,42.4311523,90.6872559,42.4311523,150
		c0,59.3129883,48.2543945,107.5686035,107.5688477,107.5686035S257.5688477,209.3129883,257.5688477,150
		C257.5688477,90.6872559,209.3144531,42.4313965,150,42.4313965z M150,250.6289062
		c-55.4853516,0-100.6289062-45.1418457-100.6289062-100.6289062S94.5146484,49.3713379,150,49.3713379
		S250.6289062,94.5129395,250.6289062,150S205.4853516,250.6289062,150,250.6289062z"
        />
        <circle
          stroke={bg}
          strokeWidth={30}
          fill={bg}
          cx="150.0009766"
          cy="149.9992676"
          r="13.8798828"
        />
      </svg>
    </g>
  );
};

export default SunLine;
