import React from "react";
import { PlanetSVGProps } from "./../types";

const JupiterLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={15}
          d="M 200.01562,47.636231 V 181.5752 h -91.47558 c 16.46582,-13.54419 51.63281,-47.43726 51.63281,-89.844487 0,-26.228027 -21.33887,-47.564453 -47.5664,-47.564453 -26.224614,0 -47.562505,21.336426 -47.562505,47.564453 h 6.939453 c 0,-22.400391 18.223632,-40.624512 40.623052,-40.624512 22.40234,0 40.62597,18.224121 40.62597,40.624512 0,50.524417 -56.30859,90.066407 -56.87793,90.459477 l -9.142576,6.32495 H 200.01562 v 67.3186 h 6.94043 V 47.636231 Z"
        />
      </svg>
    </g>
  );
};

export default JupiterLine;
