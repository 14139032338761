import React from "react";
import { PlanetSVGProps } from "./../types";

const MoonLine: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  fg,
  bg,
}) => {
  const xScale = width / 300;
  const yScale = height / 300;
  return (
    <g transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale})`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <circle
          style={{ fill: `${fg}`, stroke: `${bg}`, strokeWidth: 10 }}
          cx="150"
          cy="150"
          r="145"
        />
        <path
          stroke={bg}
          strokeWidth={12.5}
          d="M 154.74844,257.56873 C 95.43384,257.56873 47.179726,209.31291 47.179726,150 c 0,-59.312912 48.254114,-107.56871 107.568714,-107.56871 12.83614,0 25.37407,2.233108 37.26139,6.636634 l 8.81044,3.263253 -8.81722,3.246307 C 152.64072,70.070656 126.19244,108.01655 126.19244,150 c 0,41.98175 26.44828,79.92764 65.81061,94.42252 l 8.81722,3.2463 -8.81044,3.26326 c -11.88732,4.40352 -24.42525,6.63665 -37.26139,6.63665 z m 0,-208.197529 C 99.26299,49.371201 54.119644,94.512856 54.119644,150 c 0,55.48714 45.143346,100.6288 100.628796,100.6288 8.87822,0 17.60395,-1.14027 26.03824,-3.3988 -37.16311,-17.62088 -61.53415,-55.56678 -61.53415,-97.23 0,-41.66492 24.37104,-79.610809 61.53415,-97.229996 -8.43768,-2.258529 -17.16002,-3.398803 -26.03824,-3.398803 z"
        />
      </svg>
    </g>
  );
};

export default MoonLine;
