import React, { FC, useState } from "react";
import { ZodiacSVGProps } from "./types";
import { toRad, drawTiltedTick } from "./Utils";
import Aries from "./objects/Aries";
import Taurus from "./objects/Taurus";
import Gemini from "./objects/Gemini";
import Cancer from "./objects/Cancer";
import Leo from "./objects/Leo";
import Virgo from "./objects/Virgo";
import Libra from "./objects/Libra";
import Scorpio from "./objects/Scorpio";
import Ophiochus from "./objects/Ophiochus";
import Saghittarius from "./objects/Saghittarius";
import Capricorn from "./objects/Capricorn";
import Aquarius from "./objects/Aquarius";
import Pisces from "./objects/Pisces";
import InfoCard from "../InfoCard";

export interface ZodiacConstellationType {
  label: string;
  start: number;
  tag: FC<ZodiacSVGProps>;
  image: string;
  description: string;
}

export const ZODIAC_CONSTELLATIONS: ZodiacConstellationType[] = [
  {
    label: "Aries",
    start: toRad(29),
    tag: Aries,
    image: "/data/zodiac/aries.png",
    description: `## The Golden Ram

In ancient Greek mythology, Aries represents the **Golden Ram** that saved Phrixus and Helle from a dire fate. Sent by the gods, the ram flew the children across the sea to safety. While Helle fell into the waters below, Phrixus survived and honored the ram by sacrificing it to Zeus, who placed its likeness among the stars.`,
  },
  {
    label: "Taurus",
    start: toRad(53),
    tag: Taurus,
    image: "/data/zodiac/taurus.png",
    description: `## The Mighty Bull

Taurus symbolizes the **majestic bull** that captivated Zeus. To win the heart of Europa, Zeus transformed himself into a gentle white bull. When Europa climbed onto his back, he carried her across the sea to Crete, revealing his true identity. The bull was then immortalized in the night sky as Taurus.`,
  },
  {
    label: "Gemini",
    start: toRad(90),
    tag: Gemini,
    image: "/data/zodiac/gemini.png",
    description: `## The Twin Brothers

The constellation Gemini represents the **twin brothers**, Castor and Pollux. Though they shared the same mother, Castor was mortal while Pollux was immortal. When Castor died, Pollux pleaded with Zeus to share his immortality with his brother. Moved by their bond, Zeus placed them together among the stars.`,
  },
  {
    label: "Cancer",
    start: toRad(118),
    tag: Cancer,
    image: "/data/zodiac/cancer.png",
    description: `## The Giant Crab

Cancer is associated with the **giant crab** sent by Hera to distract Hercules during his battle with the Hydra. The crab nipped at Hercules' feet but was crushed under his mighty strength. In honor of its efforts, Hera placed the crab among the stars as the constellation Cancer.`,
  },
  {
    label: "Leo",
    start: toRad(138),
    tag: Leo,
    image: "/data/zodiac/leo.png",
    description: `## The Nemean Lion

Leo represents the fearsome **Nemean Lion** slain by Hercules as the first of his twelve labors. The lion's impenetrable hide made it a formidable foe. Hercules defeated it using his immense strength, and afterward, he wore the lion's skin as armor. The lion was immortalized in the heavens as Leo.`,
  },
  {
    label: "Virgo",
    start: toRad(173),
    tag: Virgo,
    image: "/data/zodiac/virgo.png",
    description: `## The Maiden of Harvest

Virgo is often associated with **Demeter**, the goddess of harvest, or her daughter **Persephone**. The constellation symbolizes the cycle of life and the changing seasons, marking the time when Persephone returns to the underworld, causing Demeter's grief and the barren winter months.`,
  },
  {
    label: "Libra",
    start: toRad(218),
    tag: Libra,
    image: "/data/zodiac/libra.png",
    description: `## The Scales of Justice

Libra represents the **scales of justice**, embodying balance and harmony. Linked to **Themis**, the goddess of law, or **Astraea**, the goddess of innocence, the constellation symbolizes the weighing of right and wrong and the pursuit of equilibrium in the universe.`,
  },
  {
    label: "Scorpio",
    start: toRad(241),
    tag: Scorpio,
    image: "/data/zodiac/scorpio.png",
    description: `## The Vengeful Scorpion

Scorpio is linked to the **scorpion** that brought down the great hunter Orion. Boasting that no creature could defeat him, Orion met his match when the scorpion's deadly sting felled him. To honor the scorpion's victory, it was placed among the stars opposite Orion, ensuring they never meet again.`,
  },
  {
    label: "Ophiochus",
    start: toRad(247),
    tag: Ophiochus,
    image: "/data/zodiac/ophiochus.png",
    description: `## The Serpent Bearer

Ophiuchus, the **Serpent Bearer**, represents **Asclepius**, the legendary healer who learned the secrets of life and death from a serpent. Fearing he might make humans immortal, Zeus struck him down with a lightning bolt. Honoring his contributions, Zeus placed Asclepius in the stars as Ophiuchus.`,
  },
  {
    label: "Sagittarius",
    start: toRad(266),
    tag: Saghittarius,
    image: "/data/zodiac/sagittarius.png",
    description: `## The Archer Centaur

Sagittarius is depicted as a **centaur archer**, associated with **Chiron**, the wise tutor of heroes like Achilles and Hercules. Renowned for his knowledge and kindness, Chiron was immortal yet wounded. Choosing to give up his immortality, he was placed among the stars as a tribute to his wisdom.`,
  },
  {
    label: "Capricorn",
    start: toRad(299),
    tag: Capricorn,
    image: "/data/zodiac/capricorn.png",
    description: `## The Sea Goat

Capricorn is the **sea goat**, a creature with the head of a goat and the tail of a fish. It's linked to **Pan**, the god of nature, who transformed himself into this hybrid to escape the monster Typhon. In recognition of his quick wit, the gods placed his image among the stars.`,
  },
  {
    label: "Aquarius",
    start: toRad(327),
    tag: Aquarius,
    image: "/data/zodiac/aquarius.png",
    description: `## The Water Bearer

Aquarius symbolizes the **water bearer**, often identified as **Ganymede**, a mortal of remarkable beauty. Zeus, enamored by him, brought Ganymede to Olympus to serve as the cupbearer to the gods. Honoring his service, Zeus placed him in the sky as the constellation Aquarius.`,
  },
  {
    label: "Pisces",
    start: toRad(351),
    tag: Pisces,
    image: "/data/zodiac/pisces.png",
    description: `## The Two Fish

Pisces represents two fish tied together, associated with **Aphrodite** and her son **Eros**. To escape the fearsome Typhon, they transformed into fish and bound themselves together to avoid separation. Their story was immortalized in the stars as a reminder of their unbreakable bond.`,
  },
];

interface Props {
  rStart: number;
  rEnd: number;
  canvas: number[];
  fg: string;
  bg: string;
}

const ZodiacCircle: FC<Props> = ({ rStart, rEnd, canvas, fg, bg }) => {
  const [selectedConstellation, setSelectedConstellation] =
    useState<null | ZodiacConstellationType>(null);

  const handleOpen = (constellation: ZodiacConstellationType) => {
    setSelectedConstellation(constellation);
  };

  const handleClose = () => {
    setSelectedConstellation(null);
  };

  return (
    <>
      <circle
        key={`zodiac-circle`}
        fill="none"
        strokeWidth="1"
        stroke={fg}
        cx="50%"
        cy="50%"
        r={rStart}
      />
      <g>
        {ZODIAC_CONSTELLATIONS.map((constellation, index) => {
          const thetaStart = constellation.start;
          const thetaEnd = ZODIAC_CONSTELLATIONS[(index + 1) % 13].start;

          const Tag = constellation.tag;
          return (
            <g key={`zodiac-${index}`}>
              {drawTiltedTick(
                rStart,
                rEnd,
                thetaStart,
                fg,
                `zodiac-tick-${constellation.label}`,
                canvas,
              )}
              <Tag
                rStart={rStart}
                rEnd={rEnd}
                thetaStart={thetaStart}
                thetaEnd={thetaEnd}
                canvas={canvas}
                fg={fg}
                bg={bg}
                onClick={handleOpen}
                info={constellation}
              />
            </g>
          );
        })}
        {selectedConstellation && (
          <InfoCard
            open={!!selectedConstellation}
            onClose={handleClose}
            image={selectedConstellation.image}
            title={selectedConstellation.label}
            description={selectedConstellation.description}
          />
        )}
      </g>
    </>
  );
};

export default ZodiacCircle;
