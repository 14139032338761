import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import { ThemeContextProvider } from "./context/ThemeContext";
import { AuthContextProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import { Container, CircularProgress, Box } from "@mui/material";
import Login from "./pages/Login";
import Register from "./pages/Register";

const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <AuthContextProvider>
        <Router>
          <Navbar />
          <Container>
            <Suspense
              fallback={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/account" element={<div>Account Page</div>} />
              </Routes>
            </Suspense>
          </Container>
        </Router>
      </AuthContextProvider>
    </ThemeContextProvider>
  );
};

export default App;
