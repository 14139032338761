import React from "react";
import { Box } from "@mui/material";
import { Astrolabe } from "../components/Astrolabe/Astrolabe";

const Home: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 129px)", // 64px (Navbar) + 32px (top margin) + 32px (bottom margin) + 1
        minHeight: "750px",
        margin: "32px 0",
      }}
    >
      <Box width={800} height={800}>
        <Astrolabe
          rCelestials={160}
          rDays={200}
          rMonths={210}
          rSeasons={230}
          rZodiac={250}
          rEnd={280}
          canvas={[800, 800]}
        />
      </Box>
    </div>
  );
};

export default Home;
