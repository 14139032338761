import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Virgo: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M273.5883789,244.9968262c-12.4804688-3.114624-24.1865234-9.1337891-34.0761719-17.4777832
	c17.1582031-17.1623535,26.9326172-40.6279297,26.9326172-64.987793c0-25.1303711-10.4033203-49.310791-28.5859375-66.6004639
	l0.0263672-10.2047119c0-20.7094727-16.8476562-37.5595703-37.5595703-37.5595703
	c-15.0888672,0-28.1210938,8.9516602-34.0859375,21.8193359c-5.9658203-12.8676758-18.9980469-21.8193359-34.0898438-21.8193359
	s-28.1240234,8.9516602-34.0898438,21.8193359c-5.9648438-12.8676758-18.9970703-21.8193359-34.0888672-21.8193359
	c-20.7119141,0-37.5595703,16.8500977-37.5595703,37.5595703h6.9394531c0-16.8837891,13.7373047-30.6196289,30.6201172-30.6196289
	c16.8818359,0,30.6191406,13.7358398,30.6191406,30.6196289v135.7028809h6.9404297V85.7260742
	c0-16.8837891,13.7373047-30.6196289,30.6191406-30.6196289s30.6201172,13.7358398,30.6201172,30.6196289v135.7028809h6.9394531
	V85.7260742c0-16.8837891,13.734375-30.6196289,30.6162109-30.6196289c16.8828125,0,30.6201172,13.7358398,30.6201172,30.6112061
	l-0.0263672,10.2064209c-18.1689453,17.3044434-28.5634766,41.4870605-28.5634766,66.6071777
	c0,24.3485107,9.7646484,47.8148193,26.9082031,64.9904785c-9.859375,8.3378906-21.5556641,14.3572998-34.0517578,17.4750977
	l1.6806641,6.7332764c13.7744141-3.4373779,26.6572266-10.1253662,37.4501953-19.4119873l0.0380859,0.0357666l0.0449219-0.0408936
	c10.828125,9.2919922,23.7207031,15.9831543,37.4814453,19.4171143l0.8701172,0.1033936l-0.0166016-3.5292969
	L273.5883789,244.9968262z M209.2954102,162.53125c0-22.6124268,9.1054688-44.4080811,25.0927734-60.3076172
	c16.0009766,15.8876953,25.1171875,37.6833496,25.1171875,60.3076172c0,22.6257324-9.1162109,44.4215088-25.1171875,60.307373
	C218.4008789,206.940918,209.2954102,185.1452637,209.2954102,162.53125z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Virgo;
