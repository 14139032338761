import React, { FC } from "react";
import { toRad, drawTiltedTick } from "./Utils";

interface Props {
  rStart: number;
  rEnd: number;
  fg: string;
  bg: string;
  canvas: number[];
}

const DayCircle: FC<Props> = ({ rStart, rEnd, fg, bg, canvas }) => {
  const days = Array.from({ length: 365 }, (_, i) => toRad((i / 365) * 360));
  return (
    <g>
      <circle
        key={`days-circle`}
        fill="none"
        strokeWidth="1"
        stroke={fg}
        cx="50%"
        cy="50%"
        r={rStart}
      />
      {days.map((d) =>
        drawTiltedTick(rStart, rEnd, d, fg, `day-tick-${d}`, canvas),
      )}
    </g>
  );
};

export default DayCircle;
