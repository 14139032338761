import React, { FC, useState } from "react";
import { toRad } from "./Utils";
import InfoCard from "../InfoCard";
import SunLine from "./objects/SunLine";
import MoonLine from "./objects/MoonLine";
import MercuryLine from "./objects/MercuryLine";
import VenusLine from "./objects/VenusLine";
import MarsLine from "./objects/MarsLine";
import JupiterLine from "./objects/JupiterLine";
import SaturnLine from "./objects/SaturnLine";
import UranusLine from "./objects/UranusLine";
import NeptuneLine from "./objects/NeptuneLine";
import PlutoLine from "./objects/PlutoLine";
import EarthCute from "./objects/EarthCute";

export interface CelestialObjectSVGProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fg: string;
  bg: string;
}

export type CelestialObjectComponent = {
  label: string;
  name: string;
  component: FC<CelestialObjectSVGProps>;
  image: string;
  description: string;
};

const CELESTIAL_OBJECTS_SETTING: CelestialObjectComponent[] = [
  {
    label: "Sun",
    name: "sun",
    component: SunLine,
    image: "/data/celestials/sun.jpg",
    description: `## The Heart of Our Solar System

The **Sun** is a G-type main-sequence star that constitutes about **99.86%** of the total mass of the solar system. Its immense gravitational pull keeps the planets, asteroids, and comets in orbit. The Sun is approximately **109 times** the diameter of Earth, and its energy supports almost all life on Earth via photosynthesis and drives Earth's climate and weather.`,
  },
  {
    label: "Moon",
    name: "moon",
    component: MoonLine,
    image: "/data/celestials/moon.jpg",
    description: `## Earth's Natural Satellite

The **Moon** is Earth's only natural satellite and the fifth-largest moon in the solar system. It has about **1/81** the mass of Earth and orbits at an average distance of **384,400 km** (about **1.28 light-seconds**). The Moon's gravitational pull causes tides on Earth and has a stabilizing effect on the planet's axial tilt, contributing to a stable climate.`,
  },
  {
    label: "Mercury",
    name: "mercury",
    component: MercuryLine,
    image: "/data/celestials/mercury.jpg",
    description: `## The Swift Planet

**Mercury** is the closest planet to the Sun, orbiting at an average distance of **57.9 million km** (about **0.39 AU**). It has a mass approximately **0.055 times** that of Earth. Mercury has no atmosphere and experiences extreme temperature variations, from **430°C** (800°F) during the day to **-180°C** (-290°F) at night. It completes an orbit around the Sun in just **88 Earth days**.`,
  },
  {
    label: "Venus",
    name: "venus",
    component: VenusLine,
    image: "/data/celestials/venus.jpg",
    description: `## Earth's Twin

**Venus** is the second planet from the Sun and is often called Earth's twin due to its similar size and composition. It has a mass about **0.82 times** that of Earth and orbits at an average distance of **108.2 million km** (about **0.72 AU**). Venus has a thick, toxic atmosphere rich in carbon dioxide, with surface temperatures averaging **465°C** (869°F), making it the hottest planet in the solar system.`,
  },
  {
    label: "Mars",
    name: "mars",
    component: MarsLine,
    image: "/data/celestials/mars.jpg",
    description: `## The Red Planet

**Mars** is the fourth planet from the Sun and is known for its reddish appearance due to iron oxide on its surface. It has a mass about **0.11 times** that of Earth and orbits at an average distance of **227.9 million km** (about **1.52 AU**). Mars has the largest volcano and canyon in the solar system and is a prime candidate for future human exploration due to its relatively hospitable conditions.`,
  },
  {
    label: "Jupiter",
    name: "jupiter",
    component: JupiterLine,
    image: "/data/celestials/jupiter.jpg",
    description: `## The Gas Giant

**Jupiter** is the fifth planet from the Sun and the largest in the solar system, with a mass about **318 times** that of Earth. It orbits at an average distance of **778.5 million km** (about **5.20 AU**). Jupiter is known for its Great Red Spot, a massive storm larger than Earth, and has at least **79 moons**, including the four large Galilean moons: Io, Europa, Ganymede, and Callisto.`,
  },
  {
    label: "Saturn",
    name: "saturn",
    component: SaturnLine,
    image: "/data/celestials/saturn.jpg",
    description: `## The Ringed Planet

**Saturn** is the sixth planet from the Sun, distinguished by its prominent ring system made up of ice and rock particles. It has a mass about **95 times** that of Earth and orbits at an average distance of **1.43 billion km** (about **9.58 AU**). Saturn has over **82 moons**, with Titan being the largest, surpassing even Mercury in size.`,
  },
  {
    label: "Uranus",
    name: "uranus",
    component: UranusLine,
    image: "/data/celestials/uranus.jpg",
    description: `## The Ice Giant

**Uranus** is the seventh planet from the Sun and has a mass about **14.5 times** that of Earth. It orbits at an average distance of **2.87 billion km** (about **19.2 AU**). Uranus is unique for its axial tilt of about **98 degrees**, causing it to rotate on its side. Its atmosphere is rich in methane, giving it a blue-green color, and it has at least **27 moons**.`,
  },
  {
    label: "Neptune",
    name: "neptune",
    component: NeptuneLine,
    image: "/data/celestials/neptune.jpg",
    description: `## The Windy Planet

**Neptune** is the eighth planet from the Sun, with a mass about **17 times** that of Earth. It orbits at an average distance of **4.5 billion km** (about **30.1 AU**). Neptune is known for its strong winds, which can reach speeds of over **2,000 km/h** (1,200 mph). It has at least **14 moons**, with Triton being the largest.`,
  },
  {
    label: "Pluto",
    name: "pluto",
    component: PlutoLine,
    image: "/data/celestials/pluto.jpg",
    description: `## The Dwarf Planet

**Pluto** was once considered the ninth planet but was reclassified as a dwarf planet in 2006. It has a mass about **0.0022 times** that of Earth and orbits at an average distance of **5.9 billion km** (about **39.5 AU**). Pluto has a highly elliptical orbit and five known moons, with Charon being the largest, almost half the size of Pluto itself.`,
  },
];

export type Celestial = {
  ecliptic: {
    lat: number;
    lon: number;
  };
};

export type Celestials = {
  [key: string]: Celestial;
};

interface Props {
  r: number;
  celestials: Celestials;
  west: number;
  fg: string;
  bg: string;
  canvas: number[];
}

const CelestialCircle: FC<Props> = ({
  r,
  celestials,
  west,
  fg,
  bg,
  canvas,
}) => {
  const [selectedObject, setSelectedObject] =
    useState<null | CelestialObjectComponent>(null);

  const handleOpen = (object: CelestialObjectComponent) => {
    setSelectedObject(object);
  };

  const handleClose = () => {
    setSelectedObject(null);
  };

  const objectDiameter = (600 / Math.min(canvas[0], canvas[1])) * 15;
  const earthDiameter = (600 / Math.min(canvas[0], canvas[1])) * 100;

  return (
    <g>
      <circle
        key={`celestials-circle`}
        fill="none"
        strokeWidth="1"
        stroke={fg}
        cx="50%"
        cy="50%"
        r={r}
      />
      <g transform={`rotate(${west - 180} ${canvas[0] / 2} ${canvas[1] / 2})`}>
        <EarthCute
          x={canvas[0] / 2 - earthDiameter / 2}
          y={canvas[1] / 2 - earthDiameter / 2}
          width={earthDiameter}
          height={earthDiameter}
          fg={fg}
          bg={bg}
        />
      </g>
      {CELESTIAL_OBJECTS_SETTING.map((object, index) => {
        const body = celestials[object.name];

        const width = objectDiameter;
        const height = objectDiameter;

        const xCenter =
          r * Math.cos(toRad(body.ecliptic.lon)) +
          canvas[0] / 2 -
          (objectDiameter * canvas[0]) / canvas[1];
        const yCenter =
          r * Math.sin(toRad(body.ecliptic.lon)) +
          canvas[1] / 2 -
          (objectDiameter * canvas[0]) / canvas[1];

        const Component = object.component;
        return (
          <g
            onClick={() => handleOpen(object)}
            cursor={"pointer"}
            transform={`rotate(${west - 180} ${xCenter + width} ${yCenter + height})`}
          >
            <Component
              key={index}
              x={xCenter}
              y={yCenter}
              width={width}
              height={height}
              fg={fg}
              bg={bg}
            />
          </g>
        );
      })}
      {selectedObject && (
        <InfoCard
          open={!!selectedObject}
          onClose={handleClose}
          image={selectedObject.image}
          title={selectedObject.label}
          description={selectedObject.description}
        />
      )}
    </g>
  );
};

export default CelestialCircle;
