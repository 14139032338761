import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Cancer: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M107.6899414,76.0163879c38.609375-4.3553467,76.2548828-3.0136719,108.6806641,3.6827393
		c-7.3955078,2.5531006-13.9365234,7.1392822-18.9326172,13.4061279
		c-6.9541016,8.7155762-10.0917969,19.6185303-8.8447266,30.6959229
		c1.2509766,11.0792236,6.7363281,21.0078125,15.4521484,27.9595947c7.4648438,5.9521484,16.5332031,9.1103516,25.9404297,9.1103516
		c1.5791016,0,3.1650391-0.0881348,4.7568359-0.2677002c22.8671875-2.5770264,39.3798828-23.2799072,36.8046875-46.1480713
		c-1.2509766-11.0791016-6.7373047-21.0095215-15.4560547-27.9595947
		c-4.7441406-3.7834473-10.1337891-6.4283447-15.8457031-7.8608398
		c-37.6025391-11.6055908-84.7617188-14.994751-133.3349609-9.5144043
		c-28.1025391,3.168335-54.8457031,9.0273438-79.4775391,17.4176025l2.2373047,6.5705566
		C53.8305664,84.879425,80.0795898,79.1289368,107.6899414,76.0163879z M264.6479492,115.2313538
		c2.1484375,19.067749-11.6162109,36.3277588-30.6845703,38.4761963
		c-9.1933594,1.0351562-18.3183594-1.5756836-25.5908203-7.3736572
		c-7.2646484-5.7945557-11.8398438-14.072998-12.8798828-23.3104248
		c-1.0439453-9.2355957,1.5761719-18.3256836,7.3701172-25.5925293c5.7949219-7.2669678,14.0732422-11.8416748,23.3105469-12.881958
		c1.3251953-0.1490479,2.6494141-0.2236328,3.9648438-0.2236328c2.8017578,0,5.5654297,0.3419189,8.2460938,0.9996338
		c1.078125,0.3337402,2.1494141,0.6723633,3.2109375,1.0200195l0.0205078-0.0622559
		c3.6279297,1.2783203,7.0556641,3.1694336,10.1523438,5.6381836
		C259.0327148,97.7171936,263.6079102,105.995636,264.6479492,115.2313538z"
      />
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M270.3305664,206.8938293c-24.1542969,8.230957-50.4023438,13.9814453-78.0166016,17.0939941
		c-38.6210938,4.3520508-76.2753906,3.0102539-108.7021484-3.6872559
		c18.0234375-6.2515869,30.0214844-24.3624268,27.7988281-44.0993652c-2.5791016-22.869873-23.25-39.3740234-46.1533203-36.8005371
		c-11.0771484,1.2487793-21.0058594,6.7366943-27.9560547,15.4522705
		c-6.953125,8.7171631-10.0917969,19.6185303-8.8447266,30.6959229
		c1.2509766,11.0791016,6.7373047,21.0095215,15.4521484,27.9595947c4.8046875,3.8314209,10.2724609,6.4975586,16.0673828,7.9165039
		c25.8085938,7.9351807,56.0898438,12.0137939,88.2451172,12.0137939c14.6523438,0,29.6982422-0.845459,44.8720703-2.5551758
		c28.1083984-3.168335,54.8486328-9.0289307,79.4736328-17.4191895L270.3305664,206.8938293z M35.355957,184.7728577v-0.001709
		c-1.0439453-9.2357178,1.5761719-18.3240967,7.3701172-25.5909424
		c5.7949219-7.2668457,14.0732422-11.8415527,23.3105469-12.8835449c1.3183594-0.1491699,2.6259766-0.2202148,3.9208984-0.2202148
		c17.4580078,0,32.5546875,13.154541,34.5537109,30.9025879c2.1484375,19.067749-11.6162109,36.3294678-30.6835938,38.4760742
		c-4.171875,0.4672852-8.3085938,0.184082-12.2636719-0.7923584c-1.0595703-0.3284912-2.1142578-0.661499-3.1582031-1.00354
		l-0.0185547,0.0551758c-3.6269531-1.2775879-7.0556641-3.1628418-10.1513672-5.6311035
		C40.9711914,202.2868958,36.3959961,194.0085754,35.355957,184.7728577z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Cancer;
