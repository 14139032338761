import React from "react";
import { ZodiacSVGProps } from "../types";
import { toDeg, calcX, calcY } from "../Utils";

const Libra: React.FC<ZodiacSVGProps> = ({
  rStart,
  rEnd,
  thetaStart,
  thetaEnd,
  canvas,
  fg,
  bg,
  onClick,
  info,
}) => {
  const r = (rStart + rEnd) / 2;

  const height = (rEnd - rStart) * 0.8;
  const width = height;
  const margin = (rEnd - rStart) * 0.1;
  const xScale = width / (canvas[0] / 2);
  const yScale = height / (canvas[1] / 2);

  const theta = (thetaStart + thetaEnd) / 2 - Math.atan(height / 2 / r);

  const rotate = toDeg(theta + Math.PI / 2);
  const x = calcX(r + (width - margin) / 2, theta, canvas);
  const y = calcY(r + (height - margin) / 2, theta, canvas);

  return (
    <g
      transform={`translate(${x}, ${y}) scale(${xScale}, ${yScale}) rotate(${rotate} 0 0)`}
    >
      <rect
        x="32.021408"
        y="207.23398"
        width="235.95718"
        height="6.9399171"
        stroke={fg}
        strokeWidth={15}
      />
      <path
        stroke={fg}
        strokeWidth={15}
        fill={bg}
        d="M 267.97858,166.2468 H 199.5994 v -23.88138 c 0,-27.34965 -22.24976,-49.599406 -49.5994,-49.599406 -27.34964,0 -49.5994,22.249756 -49.5994,49.599406 V 166.2468 H 32.021408 v -6.93993 H 93.460686 V 142.36542 C 93.460686,111.19 118.82459,85.826096 150,85.826096 c 31.17541,0 56.53932,25.363904 56.53932,56.539324 v 16.94145 h 61.43926 z"
      />
      <path
        d={"M 7,293 V 7 H 293 V 293 Z"}
        fill="rgba(0, 0, 0, 0)"
        cursor={"pointer"}
        onClick={() => onClick(info)}
      />
    </g>
  );
};

export default Libra;
