import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const loginUser = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL}/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.error || "Login failed. Please try again.",
    );
  }
};

export const refreshToken = async (token: string) => {
  try {
    const response = await axios.post(`${API_URL}/refresh`, { token });
    return response.data.access_token;
  } catch {
    return null;
  }
};

export const logoutUser = async (token: string | null) => {
  if (!token) return;
  await axios.post(
    `${API_URL}/logout`,
    {},
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

interface User {
  username: string | null;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  password: string;
}

export const registerUser = async (user: User) => {
  const { username, firstName, lastName, dateOfBirth, email, password } = user;

  try {
    const response = await fetch(`${API_URL}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
        email,
        password,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Registration failed.");
    }

    return true;
  } catch (error: any) {
    throw new Error(error.message || "An unexpected error occurred.");
  }
};

export const fetchEclipticPositions = async (date: string) => {
  try {
    const response = await fetch(`${API_URL}/astro/astrolabe`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ date }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch ecliptic positions:", error);
    throw error;
  }
};
